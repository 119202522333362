'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.default = resetMiddleware;
function resetMiddleware(option) {
  return function (next) {
    return function (reducer, initialState, enhancer) {
      var resetType = 'RESET';
      var resetData = 'state';

      if (typeof option === 'string' && option.length > 0 || (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'symbol') {
        resetType = option;
      } else if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
        resetType = typeof option.type === 'string' && option.type.length > 0 || (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'symbol' ? option.type : resetType;
        resetData = typeof option.data === 'string' && option.data.length > 0 ? option.data : resetData;
      }

      var enhanceReducer = function enhanceReducer(state, action) {
        if (action.type === resetType) {
          state = action[resetData];
        }
        return reducer(state, action);
      };

      return next(enhanceReducer, initialState, enhancer);
    };
  };
}